.offers {
  @include main-bg;
  position: relative;
  @include breakpoint-mobile {
    &::after {
      display: none;
    }
  }
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 300px 300px;
    border-color: transparent transparent $color-light-blue transparent;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  &__banner {
    position: relative;
    @include breakpoint-mobile-up {
      height: 500px;
    }
    &::after {
      content: "";
      display: inline-block;
      background: url("../images/icon-inprogress.svg") no-repeat;
      height: 44px;
      width: 30px;
      position: absolute;
      bottom: -60px;
      left: calc(50% - 15px);
    }
    .container {
      align-items: center;
    }
    @include breakpoint-mobile {
      background-size: cover;
      .container {
        flex-direction: column;
      }
    }
  }
  &__banner-img {
    margin: 0 0 0 -120px;
    padding-top: 45px;
    img {
      max-height: 410px;
    }
    @include breakpoint-mobile {
      padding-top: 0;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      img {
        max-height: 280px;
      }
    }
  }
  &__banner-content {
    color: $color-primary;
    width: 50%;
    @include breakpoint-mobile {
      padding: 0 20px;
      align-self: flex-start;
      width: 100%;
    }
  }
  &__banner-title {
    font-size: 32px;
    margin-bottom: 20px;
    @include breakpoint-mobile {
      font-size: $h3-font-size;
      margin-bottom: 0;
    }
  }
  &__banner-value {
    font-weight: $font-weight-bold;
    font-size: 80px;
    display: block;
    @include breakpoint-mobile {
      font-size: 67px;
    }
  }
  &__banner-date {
    font-size: 20px;
    @include breakpoint-mobile {
      margin-top: 0;
      font-size: $h3-font-size;
    }
    @include breakpoint-mobile-up {
      strong {
        display: block;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    z-index: 3;
    width: 100%;
    @include breakpoint-mobile {
      padding: 80px 0 20px;
    }
    &--empty {
      display: flex;
      flex-direction: column;
      font-weight: $font-weight-bold;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
  &__list-heading {
    font-size: 26px;
    padding-bottom: 35px;
    margin: 30px 0;
    color: $color-primary;
    position: relative;
    width: 100%;
    &::after {
      content: "";
      background-color: $underline-color;
      height: 4px;
      width: 50px;
      border-radius: 2px;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    @include breakpoint-mobile {
      text-align: center;
      margin-bottom: 0;
      font-size: $h2-font-size;
      &::after {
        display: none;
      }
    }
  }
}
.offer {
  &__item {
    position: relative;
    background-color: white;
    box-shadow: 0px 10px 20px $shadow-color;
    max-width: 260px;
    width: calc(25% - 15px);
    border-radius: 8px;
    margin: 0 15px 20px 0;
    .button--primary {
      width: calc(100% - 40px);
      position: absolute;
      bottom: 30px;
    }
    @include breakpoint-screen-large-down {
      width: calc(33% - 15px);
    }
    @include breakpoint-screen-medium-only {
      width: calc(50% - 15px);
    }
    @include breakpoint-mobile {
      max-width: 100%;
      width: 100%;
      margin-right: 0;
    }
  }
  &__header {
    height: 185px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 130px 20px;
    border-bottom-left-radius: 130px 20px;
    background-size: cover;
  }
  &__logo {
    max-height: 52px;
    display: inline-block;
    margin: 0 0 30px;
    align-self: flex-start;
  }
  &__content {
    padding: 30px 20px 80px;
    display: flex;
    flex-direction: column;
  }
  &__title {
    @extend .h3;
    margin-top: 0;
  }
  &__description {
    margin: 10px 0 20px;
  }
  &__value {
    font-size: 26px;
    padding: 10px 0 10px 27px;
    background-color: $color-yellow;
    border-radius: 2px 100px 100px 2px;
    border-left: 4px solid $color-primary;
    width: 160px;
    position: absolute;
    top: 27px;
    left: -4px;
  }
  &__warning {
    margin: 0 auto;
    margin-bottom: 30px;
    font-weight: $font-weight-bold;
  }
}
.offers__list--special {
  padding-top: 90px;
}
.offers__list--normal {
  padding-bottom: 90px;
}
.special-offer {
  width: 100%;
  max-width: 100%;
  border: 1px solid $color-primary;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  .offer__logo {
    max-height: 64px;
  }
  .offer__content {
    position: relative;
  }

  @include breakpoint-mobile {
    margin-bottom: 64px;
    flex-direction: column;
    .offer__header {
      height: 320px;
      width: 100%;
    }
  }
  @include breakpoint-mobile-up {
    .offer__header {
      width: 50%;
      height: 100%;
      border-top-right-radius: 30px 150px;
      border-top-left-radius: 8px;
      border-bottom-right-radius: 30px 150px;
      border-bottom-left-radius: 8px;
      z-index: 3;
    }
    .offer__content {
      width: 50%;
    }
    .offer__title {
      margin-top: 60px;
    }
    .offer__logo {
      position: absolute;
      right: 20px;
      top: 13px;
    }
    .offer__value {
      width: 300px;
      padding: 10px 0 10px 190px;
      top: 13px;
      right: calc(50% - 130px);
      left: auto;
      z-index: 2;
    }
    .button--primary {
      width: 220px;
      position: absolute;
      right: 20px;
    }
  }
}
