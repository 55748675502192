// white box for text content
@mixin box {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 10px 20px $shadow-color;
}
// yellow background image
@mixin main-bg {
  background: url("../images/main-bg.svg") no-repeat;
  background-size: 100% 500px;
  background-position: top;
}
