* {
  box-sizing: border-box;
  // print background images on print
  color-adjust: exact !important;
}
html {
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}
body {
  font-family: $font-primary;
  background-color: $background-color;
  margin: 0;
  padding: 0;
  font-size: $basic-font-size;
  line-height: 1.45;
  color: $text-color;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
}

// basic typography
p {
  font-weight: 400;
  line-height: 1.45;
}
a {
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}
input {
  outline: none;
}
img {
  max-width: 100%;
}

// headers
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  color: $color-primary;
  font-weight: $font-weight-bold;
}
.h1 {
  font-size: $h1-font-size;
  margin: 0;
}
.h2 {
  font-size: $h2-font-size;
}
.h3 {
  font-size: $h3-font-size;
}

.container {
  width: $container-width;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include breakpoint-screen-large-down {
    width: 100%;
    padding: 0 20px;
  }
}

// Expand/collapse
.collapse-trigger {
  cursor: pointer;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    background: url("../images/icon-chevron-right.svg") no-repeat;
    width: 10px;
    height: 18px;
  }
  &.expanded {
    &::after {
      transform: rotate(90deg);
    }
  }
}
.collapse-content {
  display: block;
  max-height: 0;
  padding-top: 20px;
  overflow: hidden;
  transition: max-height 0.4s ease-in;
  &.expanded {
    transition: max-height 0.4s ease-out;
    max-height: 99em;
  }
}
