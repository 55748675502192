.offer-detail {
  display: flex;
  padding: 40px 0;
  @include breakpoint-mobile {
    flex-direction: column;
    padding: 0;
  }
  &__image-container {
    position: relative;
    background-color: transparent;
    margin-right: 20px;
    width: 50%;
  }
  &__content {
    margin-left: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  &__content-box {
    box-shadow: 0px 10px 20px $shadow-color;
    border-radius: 8px;
    background-color: white;
    margin-bottom: 27px;
    padding: 30px 35px 20px;
    color: $color-primary;
  }
  &__bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    max-height: 50px;
  }
  &__value {
    font-size: 64px;
    line-height: 1;
    font-weight: $font-weight-bold;
  }
  &__button {
    align-self: flex-end;
    margin-bottom: 15px;
    form {
      display: inline-block;
    }
  }
  &__subtitle {
    margin: 0;
    font-size: 22px;
    font-weight: $font-weight-bold;
  }

  @include breakpoint-mobile-up {
    &__image {
      border-radius: 8px;
      box-shadow: 0px 10px 15px $shadow-color-light;
    }
    &__image-container {
      position: sticky;
      top: 20px;
    }
  }

  @include breakpoint-mobile {
    &__image-container {
      width: 100%;
    }
    &__image {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 130px 20px;
      border-bottom-left-radius: 130px 20px;
      box-shadow: none;
    }
    &__content {
      width: 100%;
      padding: 20px;
      margin-left: 0;
    }
    &__content-box {
      margin-bottom: 10px;
      padding: 30px 20px;
      &.main-info {
        background-color: transparent;
        padding: 30px 0 0;
        box-shadow: none;
      }
    }
    &__value {
      font-size: 44px;
    }
  }
}
