.login-page {
  @include main-bg;
  height: 100%;
  width: 100%;
  .container {
    position: relative;
    padding: 45px 0 100px;
  }
  .main-img {
    margin: 0 0 0 -120px;
    @include breakpoint-mobile-up {
      img {
        max-height: 410px;
      }
    }
  }

  @include breakpoint-screen-medium-only {
    .container {
      padding: 45px 20px 0;
    }
    .main-img {
      margin: 0;
    }
  }
  @include breakpoint-mobile {
    background-size: cover;
    flex-direction: column;
    .main-img {
      margin: 10px 0 0;
      position: absolute;
      top: 0;
    }
  }
}
.login-form {
  background-color: white;
  border-radius: 8px;
  padding: 50px 45px;
  width: 445px;
  box-shadow: 0px 10px 20px $shadow-color;

  @include breakpoint-mobile {
    width: calc(100% - 40px);
    margin: 90px 20px 0;
    padding: 20px;
    z-index: 10;
  }
  &__header {
    color: $color-primary;
    font-size: 34px;
    font-weight: bold;
    line-height: 1.2;
  }
  &__lead {
    color: $text-color;
    font-size: $basic-font-size;
    margin-bottom: 32px;
  }
  &__form-group {
    @extend .form-group-icon;
    margin-bottom: 30px;
    &::before {
      background: url("../images/icon-pin-code.svg");
    }
  }
  &__form-label {
    @extend .label;
  }
  &__form-input {
    @extend .input;
  }
  .g-recaptcha {
    margin-bottom: 30px;
  }
  &__button {
    @extend .button--primary;
    width: 100%;
  }
}
