.footer {
  background-color: white;
  flex-shrink: 0;
  .container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include breakpoint-screen-large-down {
      padding: 0 20px;
    }
    @include breakpoint-mobile {
      height: auto;
      padding: 35px 20px;
    }
  }

  &__logo {
    height: 60px;
  }
  &__menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  &__menu-item {
    font-size: $basic-font-size;
    display: inline-block;
    padding-right: 45px;
    &:last-child {
      padding-right: 0;
    }

    @include breakpoint-mobile {
      display: block;
      padding-right: 0;
      padding-bottom: 23px;
      text-align: right;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  &__menu-link {
    color: $color-primary;
    font-weight: $font-weight-bold;
    position: relative;
    padding: 10px 0;
    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 0%;
      border-bottom: 3px solid $underline-color;
      transition: 0.4s;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
    &.active {
      border-bottom: 3px solid $underline-color;
      &:hover {
        border-bottom-color: transparent;
      }
    }
  }
}
