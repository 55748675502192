.welcome-modal {
  display: none;
  background-color: white;
  border-radius: 8px;
  width: 630px;
  padding: 50px 75px;
  text-align: center;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.open {
    display: block;
  }
  @include breakpoint-mobile {
    width: 90%;
    max-height: 80%;
    overflow: auto;
    padding: 20px 20px 50px;
  }
  &__close {
    position: relative;
    height: 1px;
    cursor: pointer;
  }
  &__close-icon {
    position: absolute;
    top: -10px;
    right: -30px;
    height: 16px;
    width: 16px;
    z-index: 101;
    @include breakpoint-mobile {
      top: -10px;
      right: -10px;
    }
  }
  &__img {
    max-height: 140px;
  }
  &__timeline-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    @include breakpoint-mobile {
      padding: 0;
    }
  }
  &__timeline-text-item {
    text-align: center;
    max-width: 120px;
    opacity: 0.4;
    color: $color-primary;
    padding: 0 5px;
    @include breakpoint-mobile {
      font-size: 10px;
    }
    &:first-child {
      opacity: 1;
    }
  }
  &__description {
    color: $text-color;
    margin-top: 15px;
  }

  // Timeline modifications
  .timeline {
    justify-content: center;
    &__step {
      &.step2,
      &.step3 {
        background-color: $disabled-background-color;
      }
    }
    .bar-empty {
      background-image: url("../images/line-disabled-grey.svg");
    }
    @include breakpoint-mobile {
      &__progress-bar {
        height: 4px;
        background-size: contain;
        background-position: center;
      }
    }
  }
}
.main-page-overlay {
  @extend .header__mobile-menu-overlay;
  transition: none;
  &.open {
    width: 100%;
    transition: none;
  }
}
