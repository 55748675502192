$mobile-width: 768px;
$mobile-narrow-width: 500px;
$screen-large-width: 1100px;

@mixin breakpoint-mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin breakpoint-mobile-narrow {
  @media (max-width: #{$mobile-narrow-width}) {
    @content;
  }
}

@mixin breakpoint-mobile-up {
  @media (min-width: #{$mobile-width + 1px}) {
    @content;
  }
}

@mixin breakpoint-screen-medium-only {
  @media (min-width: #{$mobile-width + 1px}) and (max-width: #{$screen-large-width - 1px}) {
    @content;
  }
}

@mixin breakpoint-screen-large-up {
  @media (min-width: #{$screen-large-width + 1px}) {
    @content;
  }
}

@mixin breakpoint-screen-large-down {
  @media (max-width: #{$screen-large-width}) {
    @content;
  }
}
