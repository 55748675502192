@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat-bold.woff2") format("woff2"),
    url("../fonts/montserrat-bold.woff") format("woff"),
    url("../fonts/montserrat-bold.ttf") format("truetype");
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat-light.woff2") format("woff2"),
    url("../fonts/montserrat-light.woff") format("woff"),
    url("../fonts/montserrat-light.ttf") format("truetype");
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat-regular.woff2") format("woff2"),
    url("../fonts/montserrat-regular.woff") format("woff"),
    url("../fonts/montserrat-regular.ttf") format("truetype");
  font-weight: $font-weight-regular;
  font-style: normal;
}
