.simplified-cart {
  display: none;
  @include box;
  width: 375px;
  background-color: $background-color;
  border: 1px solid #00000000;
  z-index: 10;
  padding: 40px 20px;
  position: absolute;
  top: 80px;
  right: 0;
  &.open {
    display: block;
  }
  &__title {
    margin: 0;
    position: relative;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $background-color;
      position: absolute;
      top: -52px;
      left: calc(50% - 6px);
      z-index: 10;
      @include breakpoint-mobile {
        left: auto;
        right: -5px;
      }
    }
  }
  &__item {
    @extend .cart__item;
  }
  &__item-logo {
    @extend .cart__item-logo;
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }
  &__item-logo-img {
    @extend .cart__item-logo-img;
  }
  &__item-info {
    @extend .cart__item-info;
    width: calc(100% - 80px);
  }
  &__item-title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $color-primary;
    margin: 0;
  }
  &__item-quantity {
    @extend .cart__item-quantity;
    font-size: 10px;
  }
  &__summary {
    background-color: white;
    padding: 15px 20px;
    margin: 15px -20px;
  }
  &__summary-title {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
  &__summary-value {
    @extend .cart__summary-value;
  }
  &__summary-remaining {
    @extend .cart__summary-remaining;
    margin-bottom: 0;
  }
  .button--primary {
    margin: 30px 0 15px;
  }
  .button--primary,
  .button--secondary {
    width: 100%;
    display: inline-block;
  }
  &__empty-message {
    color: $color-primary;
  }
}
