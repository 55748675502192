.button {
  border-radius: 25px;
  border: 1px solid $color-primary;
  font-size: $basic-font-size;
  font-weight: $font-weight-bold;
  padding: 12px 35px;
  text-align: center;
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
  &:disabled,
  &.disabled {
    background-color: $disabled-background-color;
    border-color: $border-color;
    color: $disabled-color;
    pointer-events: none;
  }

  &--primary {
    @extend .button;
    background-color: $color-primary;
    color: white;
  }
  &--secondary {
    @extend .button;
    background-color: white;
    color: $color-primary;
  }
}
