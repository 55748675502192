.contact {
  &__header {
    @include main-bg;
    padding-top: 70px;
    @include breakpoint-screen-medium-only {
      background-size: cover;
    }
    @include breakpoint-mobile {
      background-size: cover;
      .container {
        flex-direction: column;
      }
    }
  }
  &__intro {
    max-width: 460px;
  }
  &__lead {
    font-size: 20px;
  }
  &__details {
    width: 335px;
    margin-bottom: -50px;
    z-index: 50;
    @include breakpoint-mobile {
      margin: 0 auto;
      margin-bottom: -270px;
    }
  }
  &__email,
  &__address {
    @include box;
    padding: 10px 15px;
    margin-bottom: 10px;
    display: flex;
  }
  &__icon {
    background-color: $icon-background-color;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-right: 15px;
  }
  &__icon-img {
    max-height: 30px;
    max-width: 30px;
  }
  &__text-title {
    font-weight: $font-weight-bold;
    font-size: $basic-font-size;
    margin: 0;
  }
  &__text-content {
    font-size: 13px;
    margin: 0;
  }
  &__link {
    color: $text-color;
  }
}
