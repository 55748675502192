.label {
  color: $color-primary;
  font-size: $small-font-size;
  display: block;
}
.input {
  border-width: 0 0 2px 0;
  border-color: $border-color;
  background-color: white;
  padding: 12px 0;
  color: $color-primary;
  width: 100%;
  font-size: $input-font-size;
  @include breakpoint-mobile {
    background-color: transparent;
  }
  &::placeholder {
    color: $placeholder-color;
    font-size: $input-font-size;
  }
  &:focus {
    border-color: $color-active;
  }
}
.form-group {
  margin-bottom: 15px;
  position: relative;
}
.form-group-icon {
  @extend .form-group;
  &::before {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    left: 0;
    bottom: 10px;
  }
  .input {
    padding: 12px 0 12px 35px;
  }
}
.errorlist {
  padding: 10px 0;
  margin: 0;
  list-style-type: none;
  color: red;
  font-size: $small-font-size;
}
