.cart-container {
  display: block;
  margin-bottom: 115px;
  &__heading {
    display: flex;
    align-items: center;
    margin: 40px 0 30px;
  }
  &__title {
    color: $color-primary;
    margin: 0 85px 0 0;
  }
  @include breakpoint-mobile {
    padding: 0;
    margin-bottom: 50px;
    &__heading {
      margin: 35px 0 0;
      padding: 0 20px;
      justify-content: center;
    }
    &__title {
      display: none;
    }
  }
}
.no-timeline {
  .cart-container__heading {
    margin: 50px 20px 0;
  }
  .cart-container__title {
    font-size: $h2-font-size;
    display: block;
  }
}
.cart {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: $color-primary;
  @include breakpoint-mobile {
    flex-direction: column;
  }
  &__items,
  &__address,
  .cart-final__info {
    @include box;
    padding: 50px 30px;
    width: 60%;
    margin-right: 15px;
    @include breakpoint-mobile {
      background-color: transparent;
      width: 100%;
      margin-right: 0;
      box-shadow: none;
      padding: 20px;
      &__items-title {
        display: none;
      }
    }
  }
  &__address {
    @include breakpoint-mobile {
      padding-top: 0;
    }
  }
  &__item {
    display: flex;
    width: 100%;
    position: relative;
    padding-top: 15px;
  }
  &__item-logo {
    height: 75px;
    width: 75px;
    background-color: white;
    border: 1px solid $border-color-light;
    border-radius: 50%;
    margin-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  &__item-logo-img {
    max-width: 90%;
  }
  &__item-info {
    border-bottom: 1px solid $border-color-light;
    padding: 0 0 15px 0;
    width: calc(100% - 100px);
  }
  &__item-remove {
    position: absolute;
    right: 80px;
    top: 35px;
  }
  &__item-remove-button {
    border-width: 0;
    box-shadow: none;
    background: url("../images/icon-trash.svg") no-repeat;
    background-color: transparent;
    background-size: contain;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  &__item-title {
    font-size: $h3-font-size;
    margin-bottom: 5px;
  }
  &__customer-data-title {
    margin: 40px 0 20px;
    @include breakpoint-mobile {
      margin: 15px 0;
    }
  }
  &__customer-data-item {
    margin: 0;
  }
  &__summary,
  .cart-final__summary {
    @include box;
    padding: 50px 25px;
    width: calc(40% - 15px);
    .button--primary {
      width: 100%;
    }
    .button--secondary {
      width: 100%;
      margin-top: 10px;
    }
    @include breakpoint-mobile {
      width: 100%;
      margin-top: 15px;
      padding: 20px;
    }
  }
  &__item-title,
  &__summary-title,
  &__summary-value,
  &__summary-remaining {
    display: flex;
    justify-content: space-between;
  }
  &__item-quantity {
    display: flex;
    justify-content: flex-end;
    font-size: $small-font-size;
  }
  &__items-title {
    margin-bottom: 30px;
  }
  &__summary-title {
    margin-bottom: 45px;
    @include breakpoint-mobile {
      margin: 0;
    }
  }
  &__summary-agreements {
    margin: 45px 0 50px;
    font-size: 14px;
    text-indent: -16px;
    padding-left: 16px;
    @include breakpoint-mobile {
      margin: 30px 0;
    }
  }
  &__summary-remaining {
    opacity: 0.5;
    margin-bottom: 35px;
  }
  &__summary-notice {
    margin-bottom: 35px;
    font-size: 13px;
    text-align: justify;
  }

  &__offer-name {
    padding-right: 60px;
  }

  // customer data form
  .address-form {
    &__form-group {
      @extend .form-group;
      &.icon-email,
      &.icon-phone {
        @extend .form-group-icon;
      }
      &.icon-email {
        &::before {
          background: url("../images/icon-email.svg");
        }
      }
      &.icon-phone {
        &::before {
          background: url("../images/icon-phone.svg");
        }
      }
    }
    &__form-label {
      @extend .label;
    }
    &__form-input {
      @extend .input;
    }
    @include breakpoint-mobile {
      padding-top: 0;
    }
  }
}

.cart-checkout {
  @include breakpoint-mobile {
    .cart-container__title {
      display: none;
    }
    .cart__items {
      margin-bottom: 20px;
    }
    .cart__summary {
      background-color: transparent;
      padding: 0;
    }
    .cart__summary-title {
      background-color: white;
      padding: 15px 20px;
      margin: -15px 0 -10px;
    }
    .cart__summary-value,
    .cart__summary-remaining {
      display: none;
    }
    .cart__summary-agreements-form {
      padding: 0 20px;
    }
  }
}

// success/failure page
.cart-final {
  @include breakpoint-mobile {
    .cart-container__title {
      display: none;
    }
  }
  .cart {
    @include breakpoint-mobile {
      .cart-final__info {
        margin-top: 240px;
        width: 100%;
        text-align: center;
      }
      .cart-final__img {
        position: absolute;
        top: 185px;
        left: 50%;
        transform: translateX(-50%);
        max-height: 200px;
      }
    }
    .cart-final__summary {
      @include box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include breakpoint-mobile {
        background-color: transparent;
        padding: 0;
        width: 100%;
        margin-top: 0;
      }
      .button--primary {
        margin: 30px 0 15px;
      }
    }
  }
}
