// All styles are nested inside .cms-page class
// to make it more generic as the content will be
// uploaded from the CMS

.cms-page {
  height: 100%;
  width: 100%;
  padding: 100px 0 80px;
  @include breakpoint-mobile {
    padding: 60px 0 100px;
    .container {
      flex-direction: column;
    }
  }
  &__info {
    margin-bottom: 50px;
    @include breakpoint-mobile-up {
      width: 40%;
      padding-right: 50px;
    }
  }
  &__title {
    @extend .h1;
    line-height: 1.1;
    @include breakpoint-mobile {
      font-size: 34px;
    }
  }
  &__content {
    width: 100%;
    padding: 0 20px 20px;
    @include breakpoint-mobile-up {
      padding: 20px 40px 35px;
      width: 60%;
    }
    h2 {
      @extend .h2;
      margin: 30px 0 20px;
    }
    h3 {
      @extend .h3;
    }
    > ol {
      padding-left: 15px;
    }
    ol li ol {
      list-style-type: lower-alpha;
    }
    ul {
      list-style-type: disc;
    }
    .table {
      margin: 1rem 0;
      width: 100%;
      border: 1px solid $border-color;
      border-collapse: collapse;
      tr,
      td {
        border: 1px solid $border-color;
      }
      td {
        padding: 0.5rem;
      }
      .table-header {
        background-color: $color-primary;
        color: white;
        text-align: center;
      }
      .table-header-white {
        text-align: center;
        p {
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

.terms-page {
  .cms-page__content {
    @include box;
  }
  @include breakpoint-mobile-up {
    @include main-bg;
  }
}
.help-page {
  @include main-bg;
  @include breakpoint-mobile {
    background-size: 170%;
  }
  .cms-page__content {
    padding: 0;
  }
  .cms-page__category {
    @include box;
    padding: 30px 25px 10px 35px;
    margin-bottom: 30px;
    @include breakpoint-mobile {
      padding: 30px 20px 10px;
      margin-bottom: 15px;
    }
    h2 {
      margin: 0;
      padding-right: 15px;
    }
  }
}
