.header {
  background-color: white;
  .container {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include breakpoint-screen-large-down {
      padding: 0 20px;
    }
  }
  &__logo {
    display: inline-block;
    max-height: 55px;
    max-width: 180px;
    @include breakpoint-mobile {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }
  }
  &__logo-img {
    max-height: 55px;
  }
  &__central-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    @include breakpoint-mobile {
      display: none;
    }
  }
  &__central-menu-item {
    font-size: $basic-font-size;
    display: inline-block;
    padding: 0 40px;
  }
  &__central-menu-link {
    color: $color-primary;
    font-weight: $font-weight-bold;
    position: relative;
    padding: 10px 0;
    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 0%;
      border-bottom: 3px solid $underline-color;
      transition: 0.4s;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
    &.active {
      border-bottom: 3px solid $underline-color;
      &:hover {
        border-bottom-color: transparent;
      }
    }
  }
  &__right-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }
  &__cart {
    padding: 20px 0;
  }
  &__cart-icon {
    background: url("../images/icon-basket.svg") no-repeat;
    background-size: 22px 18px;
    display: inline-block;
    height: 18px;
    width: 22px;
    position: relative;
    cursor: pointer;
  }
  &__cart-counter {
    background-color: red;
    color: white;
    font-size: 8px;
    line-height: 1;
    border-radius: 50%;
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 12px;
    height: 12px;
    text-align: center;
    font-weight: bold;
    padding: 3px 0;
  }
  &__logout {
    display: inline-block;
    padding-left: 35px;
    @include breakpoint-mobile {
      display: none;
    }
  }
  // mobile menu
  &__mobile-menu-icon {
    background: url("../images/icon-mobile-menu.svg") no-repeat;
    height: 20px;
    width: 20px;
    cursor: pointer;
    @include breakpoint-mobile-up {
      display: none;
    }
  }
  &__mobile-menu-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -275px;
    width: 275px;
    background-color: $mobile-menu-background-color;
    z-index: 100;
    transition: left 0.5s ease-out;
    &.open {
      left: 0;
      transition: left 0.5s ease-in;
    }
  }
  &__mobile-menu-close {
    background-color: $color-primary;
    padding: 20px;
  }
  &__mobile-menu-close-icon {
    display: block;
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
  &__mobile-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  &__mobile-menu-item {
    margin: 0 20px;
    padding: 25px 0;
    border-bottom: 1px solid $border-color-light;
  }
  &__mobile-menu-link {
    color: $color-primary;
    &.active {
      font-weight: $font-weight-bold;
    }
  }
  &__mobile-menu-logout {
    padding: 20px;
  }
  &__mobile-menu-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: width 0.3s ease-out;
    transition-delay: 0.2s;
    background-color: $overlay-color;
    z-index: 99;
    &.open {
      width: 100%;
      transition: width 0.3s ease-in;
      transition-delay: 0.2s;
    }
  }
}
