// Colours
$color-primary: #162056;
$color-secondary: #faaf0c;

$text-color: #323232;
$background-color: #f8f9fb;
$border-color: #afb5bc;
$border-color-light: #e6eaef;
$placeholder-color: #afb5bc;
$disabled-color: #7e8eaa;
$shadow-color: #d0d6de33;
$shadow-color-light: #0000001a;
$underline-color: #28c0d4;
$color-active: #39b54a;
$color-light-blue: #96d9fb;
$color-yellow: #fccf6e;
$mobile-menu-background-color: #eff2f7;
$overlay-color: #00000080;
$disabled-background-color: #f7f7f7;
$icon-background-color: #d4f3f7;

// Containers
$container-width: 1090px;

// Typography
$font-primary: Montserrat, sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

// Font sizes
$h1-font-size: 44px;
$h2-font-size: 22px;
$h3-font-size: 18px;
$basic-font-size: 15px;
$input-font-size: 16px;
$small-font-size: 12px;
