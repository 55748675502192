.timeline {
  display: flex;
  align-items: center;
  &__step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: $h2-font-size;
    color: $color-primary;
    font-weight: $font-weight-bold;
    margin: 5px;
  }
  .step-active {
    background-color: transparent;
    border: 3px solid $color-light-blue;
  }
  .step-disabled {
    background-color: white;
    border: 3px solid white;
  }
  &__progress-bar {
    height: 6px;
    width: 82px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bar-empty {
    background-image: url("../images/line-disabled-white.svg");
  }
  .bar-inprogress {
    background-image: url("../images/line-inprogress.svg");
  }
  .bar-full {
    background-image: url("../images/line-full.svg");
  }
  // Customer data page
  &.cart-step-first {
    .step1 {
      @extend .step-active;
    }
    .step2,
    .step3 {
      @extend .step-disabled;
    }
    .line1 {
      @extend .bar-inprogress;
    }
    .line2 {
      @extend .bar-empty;
    }
  }
  // Checkout page
  &.cart-step-second {
    .step1,
    .step2 {
      @extend .step-active;
    }
    .step3 {
      @extend .step-disabled;
    }
    .line1 {
      @extend .bar-full;
    }
    .line2 {
      @extend .bar-inprogress;
    }
  }
  // Checkout final page
  &.cart-step-third {
    .step1,
    .step2,
    .step3 {
      @extend .step-active;
    }
    .line1,
    .line2 {
      @extend .bar-full;
    }
  }
}
